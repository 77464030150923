<template>
  <div id="ate-scores">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <div class="columns is-vcentered is-centered is-multiline">
        <div class="column is-10 offset-1">
          <p class="title is-5">{{ schoolClassName }} - {{ subjectName }}</p>
          <p class="title is-6">
            {{ sessionTitle }}
          </p>
        </div>
        <div class="column is-10 offset-1">
          <b-tabs v-model="activeTab">
            <b-tab-item
              :label="getAteName(ate.name)"
              v-for="ate in studentsAteMarks"
              :key="ate.id"
            >
              <div class="columns is-vcentered is-centered is-multiline">
                <div class="column is-8 offset-2">
                  <ValidationObserver ref="observer" v-slot="{ passes }">
                    <b-table :data="ate.ateMarks" hoverable>
                      <b-table-column
                        field="#"
                        label="#"
                        width="40"
                        numeric
                        v-slot="props"
                        >{{
                          ate.ateMarks.indexOf(props.row) + 1
                        }}</b-table-column
                      >

                      <b-table-column label="Name" v-slot="props">
                        {{ props.row.student.name }}
                      </b-table-column>

                      <b-table-column label="Score" width="200" v-slot="props">
                        <SdInput
                          type="text"
                          :rules="`min_value:0|max_value:${ate.maxScore}`"
                          :name="`${ate.name}_${props.row.studentId}`"
                          v-model="props.row.score"
                          @set-change="setChange"
                        />
                      </b-table-column>

                      <template slot="footer">
                        <div class="field is-grouped is-pulled-right clear">
                          <p class="control">
                            <a
                              class="button btn-120 is-primary"
                              @click="passes(update)"
                              >Submit</a
                            >
                          </p>
                        </div>
                      </template>
                    </b-table>
                  </ValidationObserver>
                </div>
              </div>
            </b-tab-item>
          </b-tabs>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import SdInput from '../../components/SdInput'
import { ValidationObserver } from 'vee-validate'
import { crudNotification } from '@/assets/js/notification'

export default {
  name: 'ate_scores',
  data() {
    return {
      schoolId: null,
      pageTitle: 'Ate Scores',
      studentsAteMarks: [],
      ateMarks: [],
      schoolClassName: '',
      subjectName: '',
      ateName: '',
      sessionTitle: '',
      activeTab: 0,
    }
  },
  apollo: {
    studentsAteMarks: {
      query: gql`
        query StudentsAteMarksQuery($id: Int!) {
          studentsAteMarks(id: $id) {
            id
            name
            subject {
              name
            }
            schoolClass {
              name
            }
            term {
              sessionTitle
            }
            maxScore
            ateMarks {
              id
              studentId
              student {
                name
              }
              score
              marked
              createdAt
              updatedAt
            }
          }
        }
      `,
      variables() {
        return {
          id: parseInt(this.$route.params.id),
        }
      },
      fetchPolicy: 'cache-and-network',
      error(error) {
        // console.log(error);
      },
    },
  },
  watch: {
    studentsAteMarks(data) {
      const ate = data[this.activeTab]
      this.schoolClassName = ate.schoolClass.name
      this.subjectName = ate.subject.name
      this.sessionTitle = ate.term.sessionTitle
      this.ateMarks = ate.ateMarks
    },
    activeTab(value) {
      this.ateMarks = this.studentsAteMarks[value].ateMarks
    },
  },
  components: {
    ValidationObserver,
    SdInput,
  },
  methods: {
    getAteName(value) {
      if (value === 'fa') {
        return 'First Assignment'
      } else if (value === 'sa') {
        return 'Second Assignment'
      } else if (value === 'ft') {
        return 'First Test'
      } else if (value === 'st') {
        return 'Second Test'
      } else if (value === 'aff') {
        return 'Skills'
      } else if (value === 'exams') {
        return 'Exams'
      }
    },
    getData() {
      return this.ateMarks.map((ate_mark) => {
        return {
          id: ate_mark.id,
          score: ate_mark.score,
          marked: ate_mark.marked,
          created_at: ate_mark.createdAt,
          updated_at: ate_mark.updatedAt,
        }
      })
    },
    setChange(changed, inputName) {
      if (changed) {
        const studentId = inputName.split('_')[1]

        const [studentAteMark] = this.ateMarks.filter(
          (ateMark) => ateMark.studentId === parseInt(studentId)
        )
        studentAteMark.updatedAt = new Date().toUTCString()
        studentAteMark.marked = true
      }
    },
    update() {
      const data = this.getData()

      if (data.length === 0) {
        this.$buefy.toast.open({
          duration: 3000,
          message: 'No changes detected.',
          type: 'is-danger',
        })
        return
      } else {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation UpdateAteMarks($data: JSON!) {
                updateAteMarks(input: { data: $data }) {
                  ateMark {
                    id
                  }
                  errors
                }
              }
            `,
            variables: { data: data },
          })
          .then((response) => {
            crudNotification(
              response.data.updateAteMarks.errors,
              'Successfully updated.'
            ).then(() => {
              // this.$router.push(`/school/${this.schoolId}/ate_unmarked`)
            })
          })
          .catch((error) => {})
      }
    },
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)
    this.$store.commit('setSubMenus', [
      {
        name: 'Ate Unmarked',
        route: `/school/${this.schoolId}/ate_unmarked`,
      },
    ])
  },
}
</script>

<style scoped></style>
